import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ContactDialogComponent } from '@pages/home/components/contact-dialog.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mt-main-nav',
    styleUrls: ['./main-nav.component.scss'],
    templateUrl: './main-nav.component.html'
})
export class MainNavComponent {
    @ViewChild('scholarships') scholarshipTemplate: TemplateRef<any>;

    constructor(private dialog: MatDialog) {}

    openContactForm(): void {
        this.dialog.open(ContactDialogComponent, { width: '500px' });
    }

    showScholarships(): void {
        this.dialog.open(this.scholarshipTemplate, { width: '500px' });
    }
}
