<h4 mat-dialog-title>Contact Us</h4>
<div mat-dialog-content class="contact-dialog__content">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="standard">
            <mat-label>First and Last Name</mat-label>
            <input matInput type="text" name="name" formControlName="name" required>
            <mat-error *ngIf="form.controls.name.invalid">Name is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Email</mat-label>
            <input matInput type="email" name="email" formControlName="email" required>
            <mat-error *ngIf="form.controls.email.invalid">{{ getEmailErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Subject</mat-label>
            <input matInput type="text" name="subject" formControlName="subject" required>
            <mat-error *ngIf="form.controls.subject.invalid">Subject is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Message</mat-label>
            <textarea matInput name="message" formControlName="message" rows="10" required></textarea>
            <mat-error *ngIf="form.controls.message.invalid">Message is required</mat-error>
        </mat-form-field>
        <button hidden type="submit"></button>
    </form>
</div>
<div mat-dialog-actions align="end">
    <a id="submit" mat-button color="primary" [disabled]="form.invalid" [href]="emailLink" target="_blank" (click)="close()">Send</a>
    <button mat-button mat-dialog-close>Cancel</button>
</div>
