import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[mtIcon]'
})
export class IconDirective implements OnInit {
    @Input('mtIcon') name: string;

    constructor(private element: ElementRef, private renderer2: Renderer2) {}

    ngOnInit() {
        this.renderer2.setAttribute(this.element.nativeElement, 'class', `mt-icon mt-icon__${this.name}`);
    }
}
