<div class="mt-footer flow">
    <div class="mt-footer__copyright">Copyright &copy; {{ year }} California Association for Music Therapy, Inc.</div>
    <div>
        <span class="mt-footer__link">
            <a href="https://www.facebook.com/groups/1741117206138578/" target="_blank" rel="noopener">
                <i mtIcon="facebook" title="Facebook"></i>
            </a>
        </span>
        <span class="mt-footer__link">
            <a href="https://twitter.com/CAAMTinc" target="_blank" rel="noopener">
                <i mtIcon="twitter" title="Twitter"></i>
            </a>
        </span>
            <span class="mt-footer__link">
            <a href="https://www.instagram.com/caamtinc/?hl=en" target="_blank" rel="noopener">
                <i mtIcon="instagram" title="Instagram"></i>
            </a>
        </span>
    </div>
</div>
