import { NgModule } from '@angular/core';

import { FooterComponent } from '@components/footer';
import { HeaderComponent } from '@components/header';
import { MainNavComponent } from '@components/main-nav';

import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule, AppRoutingModule],
    declarations: [FooterComponent, HeaderComponent, MainNavComponent],
    exports: [AppRoutingModule, FooterComponent, HeaderComponent, MainNavComponent]
})
export class CoreModule {}
