import { Component, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'mt-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent {
    form: UntypedFormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        subject: ['', Validators.required],
        message: ['', Validators.required]
    });

    constructor(private dialogRef: MatDialogRef<ContactDialogComponent>, private elementRef: ElementRef, private formBuilder: UntypedFormBuilder) {}

    getEmailErrorMessage(): string {
        const emailControl: AbstractControl = this.form.controls.email;

        if (emailControl.hasError('required')) {
            return 'Email is required';
        }

        return emailControl.hasError('email') ? 'Email is invalid' : '';
    }

    close(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (!this.form.invalid) {
            this.elementRef.nativeElement.querySelector('#submit').click();
        }
    }

    get emailLink(): string {
        const subject = `subject=${this.form.controls.subject.value}`;
        const name = `${this.form.controls.name.value}`;
        const email = `${this.form.controls.email.value}`;
        const message = `${this.form.controls.message.value}`;
        const body = encodeURIComponent(`Name: ${name}\r\nEmail: ${email}\r\n\r\nMessage:\r\n${message}`);

        return `mailto:info@caamt.org?${subject}&body=${body}`;
    }
}
