<nav>
    <a routerLink="/home" routerLinkActive="active">Home</a>
    <a routerLink="/register" routerLinkActive="active">Register</a>
    <a (click)="showScholarships()">Scholarships</a>
    <a (click)="openContactForm()">Contact</a>
</nav>

<ng-template #scholarships>
    <h4>Select Scholarship</h4>
    <p>
        <a href="https://forms.gle/CQauUm81s6b64WgZ7" target="_blank">New Practice Grant</a>
    </p>
    <p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf1_tGZ1vjCIvvTLHARlNXZ7gW5U8zTC0SQSpTs9ELx83iRzA/viewform?usp=sf_link" target="_blank">Professional Conference Scholarship</a>
    </p>
    <p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScBG2HDpvx5_Y65WzEAjvjUb1bn25ICs5ZHxLumeZhjsG4uuA/viewform?usp=sf_link" target="_blank">Nicole Joy Ledina Student Conference Scholarship</a>
    </p>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>Cancel</button>
    </mat-dialog-actions>
</ng-template>
